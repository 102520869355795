import React, { Component } from "react";
import { Switch, Popover, message, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import Render from "../../../components/Renderer";
//import context from '../../../utils/language'
import {
  getListSettingGate,
  deleteGate,
  updateStatusGate,
} from "../../../redux/actions/setting";
import { isReadOnly } from "../../../utils/permission";
import { getGate } from "../../../redux/actions/dropdown";
import CustomTable from "../../../components/CustomTable";

class Gate extends Component {
  state = {
    form: {},
    pageSize: 20,
    data: [],
    visible: {},
    isLoading: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.getList();
    }, 500);
  }

  getList = (param) => {
    const { pageSize } = this.setState;
    this.setState({ isLoading: true });
    this.props.getListSettingGate(param).then((res) => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        const resData = res.payload.data.data;
        resData.sort((a, b) => {
          if (a.airportId === b.airportId) {
            return b.terminalId - a.terminalId;
          }
          return a.airportId > b.airportId ? 1 : -1;
        });
        let array = [],
          distince = {},
          index = 0;
        for (let d of resData) {
          // let count = data.filter(
          //   x => x.terminalId === d.terminalId && x.airportId === d.airportId
          // ).length;
          // let span = 1;
          // if (count > 1) {
          //   const countExist = array.filter(
          //     x => x.terminalId === d.terminalId && x.airportId === d.airportId
          //   ).length;
          //   span = countExist === 0 ? count : 0;
          // }
          if (distince[d.airportId] === undefined) {
            distince[d.airportId] = true;
            array.push({
              no: "",
              key: index + resData.length + 1,
              isHeader: true,
              airportId: d.airportId,
              airportName: d.airportName,
            });
          }
          array.push({
            key: index + 1,
            no: index + 1,
            id: d.gateId,
            gateId: d.gateId,
            airportId: d.airportId,
            terminalId: d.terminalId,
            terminalName: d.terminalName,
            gateEN: d.gateNameEN,
            gateTH: d.gateNameTH,
            //rowSpan: span,
            display: d.isEnable === 1,
            updateDate: d.dateUpdate,
            remark: d.remark,
            flightScope: d.flightScope,
          });
          ++index;
        }
        this.setState({ data: array });
        //this.setState({ data: resData });
      }
    });
  };

  search = () => {
    let { form } = this.state;
    let param = null;
    if (form.airportID) param = "airportId=" + form.airportID;
    this.getList(param);
  };

  onChange = ({ name, value }) => {
    let { form } = this.state;
    // if (!value)
    //   return this.setState({
    //     data: [{ key: 1, isError: true, display: true }]
    //   });
    form[name] = value;
    this.setState({ form, data: [] }, this.search);
  };

  refresh = () => {
    this.props.onRefreshDone();
    setTimeout(() => {
      this.search();
    }, 200);
  };

  edit = (item) => this.props.onEdit(this.props.name, item);
  add = (params) => this.props.onAdd(this.props.name, params);

  onDelete = (item) => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible, data: [] });
    this.props.deleteGate(item.id).then((res) => {
      if (res.status === 400) {
        message.error("ไม่สามารถลบข้อมูลได้");
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Delete Gate success");
        this.props.getGate();
      }
      this.search();
    });
  };

  onCheckChange = (item, checked) => {
    const body = {
      gateId: item.id,
      statusDisplay: checked ? 1 : 0,
    };
    this.props.updateStatusGate(body).then((res) => {
      if (res.status === 400) {
        message.error("ไม่สามารถแก้ไขข้อมูลได้");
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Update status success");
        this.props.getGate();
        this.search();
      }
    });
  };

  renderEmpty = () => ({ props: { colSpan: 0 } });

  renderText = (text, row, index) => {
    if (row.isHeader) {
      return { props: { colSpan: 0 } };
    }
    return <span className="ml-2">{text}</span>;
  };

  renderHeader = (text, row, index) => {
    const readOnly = isReadOnly("gatesetting");
    if (row.isHeader) {
      return {
        children: (
          <div className="d-flex justify-content-between">
            <span className="mr-2 font-weight-bold">{row.airportName}</span>
            {!readOnly && (
              <button
                type="button"
                onClick={() => this.add({ airportId: row.airportId })}
                className="btn btn-sm btn-primary"
              >
                <FontAwesomeIcon icon="plus" /> Add Gate
              </button>
            )}
          </div>
        ),
        props: {
          colSpan: 9,
          style: { background: "#f1f1fd" },
        },
      };
    }
    return text;
  };

  visibleChange = (item) => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible });
  };

  render() {
    const { form, data, isLoading, pageSize } = this.state;
    let dataArr = [];
    dataArr = data.map((res, i) =>
      dataArr.push({
        key: i + 1,
        no: i + 1,
        id: res.gateId,
        gateId: res.gateId,
        airportId: res.airportId,
        terminalId: res.terminalId,
        terminalName: res.terminalName,
        gateEN: res.gateNameEN,
        gateTH: res.gateNameTH,
        //rowSpan: span,
        display: res.isEnable === 1,
        updateDate: res.dateUpdate,
        remark: res.remark,
        flightScope: res.flightScope,
      })
    );
    const { refresh } = this.props;
    if (refresh) this.refresh();
    //const { language } = this.props
    const readOnly = isReadOnly("gatesetting");
    //const name = context[language].viewStatModal
    const columns = [
      {
        title: "#",
        key: "no",
        dataIndex: "no",
        width: 50,
        render: this.renderHeader,
      },
      {
        title: "Terminal",
        key: "terminal",
        align: "center",
        width: 150,
        //render: this.renderText
        render: (value, row, index) => {
          const obj = {
            children: value.terminalName,
            props: {},
          };
          if (value.rowSpan !== undefined) obj.props.rowSpan = value.rowSpan;
          if (value.isHeader) obj.props.colSpan = 0;
          return obj;
        },
      },
      {
        title: "Gate (ENG)",
        dataIndex: "gateEN",
        key: "gateEN",
        align: "center",
        width: 150,
        render: this.renderText,
      },
      {
        title: "Gate (TH)",
        dataIndex: "gateTH",
        key: "gateTH",
        width: 150,
        render: this.renderText,
      },
      {
        title: "Flight scope",
        dataIndex: "flightScope",
        key: "flightScope",
        width: 150,
        render: (text, row, index) => {
          if (row.isHeader) {
            return { props: { colSpan: 0 } };
          }
          return <span style={{textTransform: 'capitalize'}} className="ml-2">{text}</span>;
        }
      },
      {
        title: "Display (On/Off)",
        key: "display",
        width: 150,
        render: (item) =>
          item.isHeader ? (
            this.renderEmpty()
          ) : (
            <Switch
              disabled={readOnly}
              onChange={(checked) => this.onCheckChange(item, checked)}
              checked={item.display}
            />
          ),
      },
      {
        title: "Update Date",
        dataIndex: "updateDate",
        key: "updateDate",
        width: 180,
        render: this.renderText,
      },
      {
        title: "Edit",
        key: "edit",
        width: 80,
        render: (item) =>
          item.isHeader ? (
            this.renderEmpty()
          ) : (
            <button
              disabled={readOnly}
              onClick={() => this.edit(item)}
              type="button"
              className="btn btn-outline-success btn-sm"
            >
              <FontAwesomeIcon icon="pen" />
            </button>
          ),
      },
      {
        title: "Delete",
        key: "delete",
        width: 80,
        render: (item) =>
          item.isHeader ? (
            this.renderEmpty()
          ) : readOnly ? (
            <button
              disabled
              type="button"
              className="btn btn-outline-danger btn-sm"
            >
              <FontAwesomeIcon icon="trash" />
            </button>
          ) : (
            <Popover
              visible={this.state.visible[item.key]}
              onVisibleChange={(_visible) => {
                let { visible } = this.state;
                visible[item.key] = _visible;
                this.setState({ visible });
              }}
              content={
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => this.onDelete(item)}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => this.visibleChange(item)}
                  >
                    Cancel
                  </button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button
                disabled={readOnly}
                onClick={this.delete}
                type="button"
                className="btn btn-outline-danger btn-sm"
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          ),
      },
    ];
    const sum = columns.reduce((a, b) => a + b.width, 0);
    const table = (
      <CustomTable
        pagination={false}
        className="tbody-center bg-white"
        columns={columns}
        onPageSizeChange={(item) => this.setState({ pageSize: item })}
        bordered
        footer={
          isLoading
            ? () => (
                <div className="text-center">
                  <Spin className="pr-2" />
                  loading...
                </div>
              )
            : null
        }
        // scroll={{ x: sum + "px", y: "60vh" }}
        dataSource={data}
      />
    );

    const template = [
      {
        type: "select",
        name: "airportID",
        label: "Airports",
        value: form.airportID,
        placeholder: "Select Airport...",
        options: {
          name: "airportDOAOptions",
          id: "airportID",
          text: "airportName",
          preFilter: (q) => q.statusDisplay === 1,
        },
      },
    ];
    return (
      <div className="card card-body card-body-component">
        <div className="form-row form-inline col-md-12 mb-3 px-0">
          <div className="col-md-3">
            {!readOnly && (
              <button
                type="button"
                onClick={this.props.onNew}
                className="btn btn-success"
              >
                <FontAwesomeIcon icon="plus" /> Add Gate
              </button>
            )}
          </div>
          <div className="col-md-9 form-inline justify-content-end px-0">
            {template.map((t, i) => (
              <div className="col-md-3 justify-content-end pr-0" key={i}>
                {Render(t, this.onChange)}
              </div>
            ))}
            <button
              onClick={() => this.setState({ form: {} }, this.search)}
              className="btn btn-sm btn-dark ml-2"
            >
              <FontAwesomeIcon icon="redo" />
              <span className="ml-2">clear</span>
            </button>
          </div>
        </div>
        {table}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  language: state.language,
});

const mapDispatchToProps = (dispatch) => ({
  getListSettingGate: (param) => dispatch(getListSettingGate(param)),
  deleteGate: (id) => dispatch(deleteGate(id)),
  getGate: () => dispatch(getGate()),
  updateStatusGate: (body) => dispatch(updateStatusGate(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Gate);
