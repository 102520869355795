import React, { Component } from "react";
import IconSchedule from "../../images/icons/icon-schedule-lg.png";
import Iconplane from "../../images/icons/icon-plane-lg.png";
import IconReport from "../../images/icons/icon-report-lg.png";
import IconForm from "../../images/icons/icon-form-lg.png";
import IconDashboard from "../../images/icons/icon-dashboard-lg.png";
import IconSetting from "../../images/icons/icons-settings.png";
import IconUserSetting from "../../images/icons/icons-user-settings.png";
import { Link } from "react-router-dom";
import { isAccessable, isAccessableMenu } from "../../utils/permission";
import { connect } from "react-redux";

class Menu extends Component {
  render() {
    const { permission } = this.props;
    return (
      <div className="row">
        {isAccessableMenu("Flight Schedule") && (
          <div className="col-12 col-lg-6">
            <Link
              to="/flightdetail"
              className="card card-menu card-menu-schedule flex-row"
            >
              <span className="menu-icon">
                <img src={IconSchedule} alt="IconSchedule" />
              </span>
              <span className="menu-title">
                <h2 className="text-white">Flight Schedule</h2>
                <h4 className="text-white">ข้อมูลตารางการบิน</h4>
              </span>
            </Link>
          </div>
        )}
        {isAccessable("statistics") && (
          <div className="col-12 col-lg-6">
            <Link
              to='/statistics'
              className="card card-menu card-menu-statistics flex-row"
            >
              <span className="menu-icon">
                <img src={Iconplane} alt="Iconplane" />
              </span>
              <span className="menu-title">
                <h2 className="text-white">Air Transport Statistics</h2>
                <h4 className="text-white">ข้อมูลสถิติการขนส่งทางอากาศ</h4>
              </span>
            </Link>
          </div>
        )}
        {isAccessable("operationreport") && (
          <div className="col-12 col-lg-4">
            <Link
              to="/operationreport"
              className="card card-menu card-menu-report justify-content-between"
            >
              <span className="menu-icon">
                <img src={IconReport} alt="IconReport" />
              </span>
              <span className="menu-title text-center">
                <h3>Operation Reports</h3>
                <h5>รายงานสถิติการขนส่งทางอากาศ</h5>
              </span>
            </Link>
          </div>
        )}
        {isAccessable("formmanagement") && (
          <div className="col-12 col-lg-4">
            <Link
              to="/formmanagement"
              className="card card-menu card-menu-form justify-content-between"
            >
              <span className="menu-icon">
                <img src={IconForm} alt="IconForm" />
              </span>
              <span className="menu-title text-center">
                <h3>Form Management </h3>
                <h5>ระบบจัดการฟอร์ม</h5>
              </span>
            </Link>
          </div>
        )}
        {isAccessable("dashboard") && (
          <div className="col-12 col-lg-4">
            <Link
              to="/dashboard"
              className="card card-menu card-menu-dashboard justify-content-between"
            >
              <span className="menu-icon">
                <img src={IconDashboard} alt="IconDashboard" />
              </span>
              <span className="menu-title text-center">
                <h3>Dashboard</h3>
                <h4>(Power BI)</h4>
                <h5>รายงานสรุปข้อมูลเชิงสถิติ</h5>
              </span>
            </Link>
          </div>
        )}
        {(isAccessableMenu("Airline Setting") ||
          isAccessableMenu("Airport Setting") ||
          isAccessableMenu("Flight Setting") ||
          isAccessableMenu("Calculation of Service Setting")) && (
          <div className="col-12 col-lg-4">
            <Link
              to="/airlinesetting"
              className="card card-menu card-menu-form justify-content-between"
            >
              <span className="menu-icon">
                <img src={IconSetting} alt="" />
              </span>
              <span className="menu-title text-center">
                <h3 style={{ color: "#333333" }}>Setting</h3>
                <h5>ตั้งค่า</h5>
              </span>
            </Link>
          </div>
        )}
        {isAccessableMenu("User Permission") && (
          <div className="col-12 col-lg-4">
            <Link
              to={
                permission && permission.isAdminAirline === 1
                  ? "/airline/viewuser/" + permission.airlineId
                  : "/department"
              }
              className="card card-menu card-menu-form justify-content-between"
            >
              <span className="menu-icon">
                <img src={IconUserSetting} alt="" />
              </span>
              <span className="menu-title text-center">
                <h3>User Management</h3>
                <h5>ตั้งค่าผู้ใช้</h5>
              </span>
            </Link>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  permission: state.permission
});

export default connect(mapStateToProps)(Menu);
