import React, { Component } from "react";
import Render from "../../../components/Renderer";
import { Divider, message, Popover } from "antd";
import { isReadOnly } from "../../../utils/permission";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "antd";
import { insertAirType, updateAirType } from "../../../redux/actions/setting";
import { getAirType } from "../../../redux/actions/dropdown";
import { connect } from "react-redux";
import { Constraints, table as tConstraints } from "./Constraints";
import validatejs from "validate.js";

class AirTypeEdit extends Component {
  state = {
    form: {},
    fileList: [],
    data: [{ key: 1, isError: true }],
    deleted: [],
    disabledSave: false,
    visible: {}
  };

  componentDidMount() {
    const { selected } = this.props;
    let { form } = this.state;
    form = selected || { display: true };
    let data = [];
    if (selected && selected.list) data = selected.list;
    data.forEach((x, i) => (x.key = i));
    data.push({ key: data.length + 1, isError: true });
    this.setState({ form, errors: validatejs(form, Constraints), data: data });
  }

  onDelete = index => {
    let { data, deleted } = this.state;
    if (data[index].airTypeId !== undefined) deleted.push(data[index]);
    data.splice(index, 1);
    this.setState({ data, deleted });
    let { visible } = this.state;
    visible[index] = false;
    this.setState({ visible });
  };

  onAdd = () => {
    let { data } = this.state;
    data.push({ isError: true });
    data.forEach((x, i) => (x.key = i));
    this.setState({ data });
  };

  onChange = ({ name, value, error }) => {
    let { form, errors, data } = this.state;
    form[name] = value;
    if (errors === undefined) errors = {};
    if (error === undefined) delete errors[name];
    else errors[name] = error.join();
    if (Object.keys(errors).length === 0) errors = undefined;
    this.setState({ 
      form, 
      errors,
      ...name === 'display' && {
        data: data.map(e => ({
          ...e,
          isEnable: e.isError ? false : value
        }))
      }
    });
  };

  onChangeTable = (index, name, value) => {
    let { data, form } = this.state;
    data[index][name] = value;
    for (let item of data) {
      const valid = validatejs(item, tConstraints);
      item.isError = valid !== undefined;
    }    
    if (name === 'isEnable') {
      if (Boolean(form.display) && !data.some(e => e.isEnable)) {
        form['display'] = false
      }

      if (!Boolean(form.display) && data.some(e => e.isEnable)) {
        form['display'] = true
      }
    }
    this.setState({ data, form });

  };

  renderInput = (name, text, index) => {
    var template = {
      type: "input",
      name: name,
      number: true,
      //value: parseInt(text) < 0 ? 0 : parseInt(text),
      value: text,
      placeholder: ""
    };
    return Render(template, ({ name, value }) =>
      this.onChangeTable(index, name, value)
    );
  };

  renderSwitch = (name, value, index) => {
    var template = {
      type: "switch",
      name: name,
      value,
      placeholder: "",
    };
    return Render(template, ({ name, value }) =>
      this.onChangeTable(index, name, value)
    );
  };

  onSave = () => {
    this.setState({ disabledSave: true }, () => {
      const { selected } = this.props;
      const { form, data, deleted } = this.state;

      const clean = data.filter(x => !x.isError);
      const notClean = data.filter(x => x.airTypeId > 0 && x.isError);
      if (clean.length <= 0) {
        this.setState({ disabledSave: false });
        return message.error(
          "กรุณากรอกข้อมูล Gross Weigt & Pax อย่างน้อย 1 รายการ"
        );
      }
      if (notClean.length > 0) {
        this.setState({ disabledSave: false });
        return message.error("กรุณากรอกข้อมูล Gross Weigt & Pax ให้ครบถ้วน");
      }
      if (!selected) {
        let body = {
          airTypeCode: form.airtype,
          isEnable: form.display ? 1 : 0,
          listData: clean
          .filter(x => x.grossWeight !== undefined && x.pax !== undefined)
          .map(x => ({
              aircraftWidth: x.aircraftWidth,
              aircraftLength: x.aircraftLength,
              freight: x.grossWeight,
              pax: x.pax,
              isEnable: x.isEnable ? 1 : 0,
              airTypeId: form.airTypeId,
            }))
        };
        this.props.insertAirType(body).then(res => {
          this.setState({ disabledSave: false });
          if (res.status === 400) {
            message.error("ไม่สามารถเพิ่มข้อมูลได้");
          } else if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            body.airTypeID = res.payload.data.data.id;
            this.props.getAirType().then(res => {
              message.success(
                "Insert Air Type " + body.airTypeCode + " success"
              );
              this.props.onRefresh(this.props.name, body);
              this.props.getAirType();
            });
          }
        });
      } else {
        let body = {
          airTypeCode: form.airtype,
          isEnable: form.display ? 1 : 0,
          listData: clean.map(x => ({
            aircraftWidth: x.aircraftWidth,
            aircraftLength: x.aircraftLength,
            airTypeId: x.airTypeId,
            freight: Number(x.grossWeight),
            pax: Number(x.pax),
            isEnable: x.isEnable ? 1 : 0,
          }))
        };
        body.listData.forEach(
          x => x.airTypeId === undefined && delete x.airTypeId
        );
        deleted.forEach(x => {
          body.listData.push({
            isDelete: 1,
            airTypeId: x.airTypeId,
            freight: Number(x.grossWeight),
            pax: Number(x.pax),
            isEnable: x.isEnable ? 1 : 0,
          });
        });
        this.props.updateAirType(body).then(res => {
          this.setState({ disabledSave: false });
          if (res.status === 400) {
            message.error("ไม่สามารถเพิ่มข้อมูลได้");
          } else if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            this.props.getAirType().then(res => {
              message.success(
                "Update Air Type " + body.airTypeCode + " success"
              );
              this.props.onRefresh(this.props.name, body);
              this.props.getAirType();
            });
          }
        });
      }
    });
  };

  visibleChange = item => {
    let { visible } = this.state;
    visible[item] = false;
    this.setState({ visible });
  };

  render() {
    const { hideTable, disableAirType } = this.props;
    const { form, data, errors, disabledSave } = this.state;
    const readOnly = isReadOnly("airtypesetting");
    const template = [
      {
        type: "input",
        name: "airtype",
        label: "Air Type",
        constraint: Constraints,
        disabled: readOnly || disableAirType !== undefined,
        value: form.airtype
      },
      {
        type: "switch",
        name: "display",
        disabled: readOnly || form.isDisabled,
        label: "Display (On/Off)",
        value: form.display
      }
    ];
    const columns = [
      {
        title: "Gross Weight (kg.)",
        key: "grossWeight",
        dataIndex: "grossWeight",
        width: 150,
        render: (text, row, index) =>
          this.renderInput("grossWeight", row.grossWeight, index)
      },
      {
        title: "Pax",
        dataIndex: "pax",
        key: "pax",
        width: 150,
        render: (text, row, index) => this.renderInput("pax", row.pax, index)
      },
      {
        title: "Width (Meter)",
        dataIndex: "aircraftWidth",
        key: "aircraftWidth",
        width: 150,
        render: (text, row, index) => this.renderInput("aircraftWidth", row.aircraftWidth, index)
      },
      {
        title: "Legnth (Mether)",
        dataIndex: "aircraftLength",
        key: "aircraftLength",
        width: 150,
        render: (text, row, index) => this.renderInput("aircraftLength", row.aircraftLength, index)
      },
      {
        title: "Display (On/Off)",
        dataIndex: "isEnable",
        key: "isEnable",
        width: 150,
        render: (text, row, index) => this.renderSwitch("isEnable", row.isEnable, index, )
      },
      {
        title: "Edit",
        key: "edit",
        width: 80,
        render: (text, row, index) =>
          index + 1 === data.length ? (
            <button
              type="button"
              className="btn btn-sm btn-outline-success"
              onClick={() => {
                this.onAdd(index);
              }}
            >
              <FontAwesomeIcon icon="plus" />
            </button>
          ) : (
            <Popover
              visible={this.state.visible[index]}
              onVisibleChange={_visible => {
                let { visible } = this.state;
                visible[index] = _visible;
                this.setState({ visible });
              }}
              content={
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => {
                      this.onDelete(index);
                    }}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => this.visibleChange(index)}
                  >
                    Cancel
                  </button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button
                type="button"
                className="btn btn-sm btn-outline-danger"
                onClick={this.delete}
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          )

        // <button
        //   onClick={() => {
        //     index + 1 === data.length
        //       ? this.onAdd(index)
        //       : this.onDelete(index);
        //   }}
        //   type="button"
        //   className={
        //     "btn btn-sm " +
        //     (index + 1 === data.length
        //       ? "btn-outline-success"
        //       : "btn-outline-danger")
        //   }
        // >
        //   <FontAwesomeIcon
        //     icon={index + 1 === data.length ? "plus" : "trash"}
        //   />
        // </button>
      }
    ];
    const sum = columns.reduce((a, b) => a + b.width, 0);
    const table = (
      <Table
        style={{ minWidth: 900 }}
        pagination={false}
        scroll={{ x: sum + "px" }}
        className="tbody-center bg-white"
        columns={columns}
        rowClassName={(record, index) => {
          if (record.isError && index !== data.length - 1) return "bg-danger";
        }}
        dataSource={data}
      />
    );
    return (
      <div className="card card-body card-body-component">
        {template.map((data, i) => {
          return (
            <div key={i} className="form-row form-inline mb-2 col-12 px-0">
              <label className="col-lg-4 col-md-4 col-sm-12 col-12 justify-content-end">
                {data.label} :
              </label>
              <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                {Render(data, this.onChange)}
              </div>
            </div>
          );
        })}
        {hideTable === undefined && table}
        <Divider />
        {/* footer */}
        <div className="text-center mt-2">
          <button
            onClick={() => this.props.onClose(this.props.name)}
            className="btn btn-light mr-2"
          >
            Cancel
          </button>
          <button
            disabled={errors !== undefined || disabledSave}
            onClick={this.onSave}
            className="btn btn-success mr-2"
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  updateAirType: body => dispatch(updateAirType(body)),
  insertAirType: body => dispatch(insertAirType(body)),
  getAirType: () => dispatch(getAirType())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AirTypeEdit);
