import React, { Component } from "react";
import Logo from "../../images/logo.png";
import { connect } from "react-redux";
import context from "../../utils/language";
import { login, getPermission, bypassLogin } from "../../redux/actions/login";
import { setToken } from "../../redux/actions/token";
import { setPermission } from "../../redux/actions/permission";
import { message, Alert, Button } from "antd";

class BypassLogin extends Component {
  state = {
    user: "",
    pass: "",
    error: false,
    loading: false
  };

  componentDidMount() {
    this.signinWithToken()
  }
  
  signinWithToken = async () => {
    const token = new URLSearchParams(window.location.search).get('token')

    // back to the login page
    if (!token) window.location.href = '/'

    const { bypassLogin } = this.props

    this.setState({ loading: true }, () => {
      bypassLogin(token).then(res => {
        if (
          res &&
          res.error &&
          (res.error.status === 0 || res.error.response.status !== 401)
        ) {
          this.setState({ loading: false, error: true });
          return message.error(
            <span>
              ไม่สามารถเชื่อมต่อระบบสถิติได้ 
              <br />
              กรุณาตรวจสอบระบบเครือข่ายอินเตอร์เน็ตของท่าน
            </span>
          );
        } else if (
          res &&
          res.payload &&
          res.payload.status === 200 &&
          res.type.endsWith("SUCCESS")
        ) {
          this.setState({error: true, loading: false });
          this.props.setToken(res.payload.data.access_token);
          this.props.getPermission().then(res => {
            if (
              res &&
              res.payload.status === 200 &&
              res.type.endsWith("SUCCESS")
            ) {
              this.props.setPermission(res.payload.data.data);
              this.props.onLoginDone();
            }
          });
          //this.props.setPermission(res.payload.data.permissionAccess)
        } else {
          this.setState({ error: true, loading: false });
        }
      });
    });

  };

  onKeyPress = e => e.key === "Enter" && this.onLogin();

  render() {
    const { language } = this.props;
    const name = context[language].loginForm;
    return (
      <div className="container pt-5">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="d-inline-flex align-items-center text-white mb-4">
              <img src={Logo} alt="Logo" className="img-logo mr-3" />
              <span>
                <h2 className="text-white">{name && name.nameProject}</h2>
                <h4 className="text-white">{name && name.nameGovOffice}</h4>
              </span>
            </div>
          </div>
          <div className="col-12 col-lg-12" style={{ height: '500px' }}>
            <div className="d-flex justify-content-center align-items-center h-100">
              {
                this.state.loading && <div className="spinner"></div>
              }
              {
                this.state.error && <div className="text-center">
                  <div>
                    <Alert message="ไม่สามารถเชื่อมต่อระบบสถิติได้" type="error" showIcon />
                  </div>
                  <br/>
                  <div>
                    <Button onClick={() => window.location.href = 'https://stats-user-management-web.piesoft.net/login'}>
                      กลับหน้า Login
                    </Button>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  bypassLogin: token => dispatch(bypassLogin(token)),
  login: credential => dispatch(login(credential)),
  setToken: token => dispatch(setToken(token)),
  setPermission: data => dispatch(setPermission(data)),
  getPermission: () => dispatch(getPermission())
});

export default connect(mapStateToProps, mapDispatchToProps)(BypassLogin);
