import React, { Component } from "react";
import "./Main.css";
import { connect } from "react-redux";
import Router from "../Router";
import RouterAdminAirline from "../RouterAdminAirline";
import {
  getAirline,
  getAirTypeOnly,
  getAirTypeInSetting,
  getFlightStatus,
  getApproveStatus,
  getFilterAirtypeCode,
  getCallsign,
  getCallsignOnly,
  getArrDep,
  getAirportDOA
} from "../../redux/actions/dropdown";
import { message } from "antd";
import { setSearch } from "../../redux/actions/search";
import LoginRouter from "../Login";

class Main extends Component {
  componentDidMount() {
    const { token } = this.props;
    let pathname = { pathname: "/" };
    this.props.setSearch(pathname);
    token && this.onLoginDone();
  }

  onLoginDone = () => {
    const { dropdown } = this.props;
    // if (dropdown.airPortOptions.length === 0) this.props.getAirport();
    if (dropdown.airlineOptions.length === 0) this.props.getAirline();
    this.props.getAirTypeInSetting();
    this.props.getAirTypeOnly();
    if (dropdown.flightStatusOptions.length === 0) this.props.getFlightStatus();
    if (dropdown.approveStatusOptions.length === 0)
      this.props.getApproveStatus();
    this.props.getCallsign();
    this.props.getCallsignOnly();
    if (dropdown.arrDepOptions.length === 0) this.props.getArrDep();
    if (dropdown.airportDOAOptions.length === 0) this.props.getAirportDOA();
    if (window.location.pathname.includes('bypass-sign-in')) {
      window.location.replace('/')
    }
  };

  onError = error => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      message.error("Connection Error : " + JSON.stringify(error));
      this.props.setError(null);
    }, 1000);
  };

  render() {
    const { token, permission } = this.props;
    if (!token) return (
      <>
        <LoginRouter onLoginDone={this.onLoginDone}/>
      </>
    );
    const { error } = this.props;
    if (error.length > 0) this.onError(error);
    if (permission && permission.isAdminAirline === 1)
      return <RouterAdminAirline />;
    return <Router />;
  }
}

const mapStateToProps = state => ({
  dropdown: state.dropdown,
  language: state.language,
  token: state.token,
  error: state.error,
  permission: state.permission
});

const mapDispatchToProps = dispatch => ({
  setError: data => dispatch({ type: "REQUEST_ERROR", data }),
  // getAirport: () => dispatch(getAirport()),
  getAirline: () => dispatch(getAirline()),
  getAirTypeOnly: () => dispatch(getAirTypeOnly()),
  getAirTypeInSetting: () => dispatch(getAirTypeInSetting()),
  getFilterAirtypeCode: () => dispatch(getFilterAirtypeCode()),
  getFlightStatus: () => dispatch(getFlightStatus()),
  getApproveStatus: () => dispatch(getApproveStatus()),

  getCallsign: () => dispatch(getCallsign()),
  getCallsignOnly: () => dispatch(getCallsignOnly()),

  getArrDep: () => dispatch(getArrDep()),
  getAirportDOA: () => dispatch(getAirportDOA()),

  setSearch: item => dispatch(setSearch(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
