export default {
  header: {
    app: 'กรมท่าอากาศยาน'
  },
  info: {
    title: 'ระบบจัดการข้อมูลสถิติการขนส่งทางอากาศ',
    subtitle1: 'จัดการข้อมูลแผนตารางการบินของแต่ละท่าอากาศยาน',
    subtitle2: 'จัดการข้อมูลสถิติการขนส่งทางอากาศยานของแต่ละเที่ยวบิน'
  },
  loginForm: {
    nameProject: 'ระบบจัดการข้อมูลสถิติการขนส่งทางอากาศ',
    nameGovOffice: 'กรมท่าอากาศยาน',
    button: 'Login',
    copyright: 'ลิขสิทธิ์ @2562 กรมท่าอากาศยาน',
    userName: 'Username',
    password: 'Password',
    errorUsername: 'กรุณาใส่ชื่อผู้ใช้และรหัสผ่าน',
    error: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง'
  },
  nav: {
    viewFile: 'View File',
    schedule: 'ข้อมูลตารางการบิน',
    flightDetail: 'ข้อมูลเที่ยวบินขาเข้า - ขาออก',
    management: 'จัดการแผนตารางการบิน',
    detail: 'รายละเอียด',
    cancel: 'ยกเลิกเที่ยวบิน',
    edit: 'แก้ไข',
    add: 'เพิ่ม',
    flightSlot: 'Slot เที่ยวบิน',
    counterSlot: 'จัดการเคาน์เตอร์เช็คอิน / สายพาน',
    airtransportStat: 'สถิติการขนส่ง ทางอากาศ',
    operationReport: 'รายงานสถิติ การขนส่งทางอากาศ',
    formManagement: 'ระบบ จัดการฟอร์ม',
    userManagement: 'ข้อมูล ผู้ใช้งาน',
    setting: 'การตั้งค่า',
    dashboard: 'Dashboard (Power BI)',
    traffType: 'Traff Type',
    airPorts: 'Airports',
    airline: 'Airline',
    callsign: 'Callsign',
    airType: 'Air Type',
    flightStatus: 'Flight Status',
    counterChk: 'Counter Chk-in / Belt',
    flight: 'Flight',
    calculationOfService: 'Calculation of Service',
    department: 'Department of Airports',
    addGroup: 'Add Group',
    editGroup: 'Edit Group',
    viewUser: 'View User',
    addUser: 'Add User',
    editUser: 'Edit User',
    createAdmin: 'Create Admin Account',
    editAdmin: 'Edit Admin Account',
    viewall: 'View All',
    changePassword: 'Change Password',
    selectAirport: 'Select Airport',
  },
  search: {
    airport: 'ท่าอากาศยาน',
    airportPlaceholder: 'เลือกท่าอากาศยาน',
    date: 'วันที่',
    datePlaceholder: 'เลือกวันที่',
    airline: 'สายการบิน',
    airlinePlaceholder: 'เลือกสายการบิน',
    flightNo: 'เที่ยวบิน ',
    flightNoPlaceholder: 'เลือกเที่ยวบิน ',
    clear: 'คืนค่า',
    search: 'ค้นหา',
    more: 'เพิ่มเติม',
    less: 'ซ่อน',
    advanceSearch: 'ค้นหาขั้นสูง',
    betweenDate: 'วันที่',
    betweenDatePlaceholder: 'เลือกวันที่',
    betweenTime: 'เวลา ',
    betweenTimePlaceholder: 'เลือกเวลา ',
    callsign: 'Callsign',
    callsignPlaceholder: 'เลือก Callsign',
    airType: 'แบบอากาศยาน',
    airTypePlaceholder: 'เลือกแบบอากาศยาน',
    trafftype: 'รหัสการบิน',
    trafftypePlaceholder: 'เลือกรหัสการบิน',
    destination: 'ต้นทาง / ปลายทาง',
    destinationPlaceholder: 'เลือก ต้นทาง / ปลายทาง',
    checkInOut: 'ขาเข้า / ขาออก',
    all: 'ทั้งหมด',
    arrival: 'ขาเข้า',
    departure: 'ขาออก',
    cardNo: 'Card No',
    cardNoPlaceholder: 'เลือก Card No',
    flightStatus: 'สถานะ',
    flightStatusPlaceholder: 'เลือกสถานะ'
  },
  searchTable: {
    approveFlight: 'อนุมัติข้อมูลเที่ยวบิน',
    rejectFlight: 'ส่งข้อมูลเที่ยวบิน',
    confirm: 'ยืนยัน',
    cancel: 'ยกเลิก',
    approve: 'อนุมัติ',
    approved: 'อนุมัติแล้ว',
    reject: 'ส่งกลับท่าอากาศยาน',
    view: 'ดู / แก้ไขข้อมูล',
    waiting: 'รออนุมัติ',
    flightNo: 'เที่ยวบิน',
    done: 'สำเร็จ',
    today: 'วันนี้'
  },
  viewStatModal: {
    gete: 'Gate',
    getePlaceholder: 'เลือก Gate',
    title: 'จัดการ/เรียกดูข้อมูลสถิติการข่นส่งทางอากาศ',
    waitingApprove: 'รออนุมัติ',
    waitingEdit: 'รอแก้ไข',
    approved: 'อนุมัติ',
    draft: 'บันทึกร่าง',
    saveDone: 'บันทึกสำเร็จ',
    saveDoneDesc: 'บักทึกรายการสำเร็จแล้ว',
    flightDetail: 'ข้อมูลเที่ยวบิน',
    operator: 'Operator',
    operatorPlaceholder: 'ระบุ Operator',
    operatorDesc: 'โปรดระบุข้อมูล',
    recNo: 'Rec No',
    recNoPlaceholder: 'ระบุ Rec No',
    portType: 'Port Type',
    portTypePlaceholder: 'เลือก Port Type',
    portNameICAO: 'Port Name (ICAO)',
    portNameICAOPlaceholder: 'เลือก Port Name (ICAO)',
    noData: 'ไม่พบข้อมูล',
    trnDate: 'TRN Date',
    trnDatePlaceholder: 'เลือกวันที่',
    trnTime: 'TRN Time',
    trnTimePlaceholder: 'ระบุ TRN Time',
    actualTime: 'Actual Time',
    actualTimePlaceholder: 'Fill Actual Time',
    trnDay: 'TRN Day',
    trnDayPlaceholder: 'เลือก TRN Day',
    airline: 'Airline',
    airlinePlaceholder: 'เลือก Airline',
    callsign: 'Callsign',
    callsignPlaceholder: 'เลือก Callsign',
    airtype: 'Air Type',
    airtypePlaceholder: 'เลือก Air Type',
    flightNo: 'Flight No',
    flightNoPlaceholder: 'ระบุ Flight No',
    scheduleTime: 'Schedule Time',
    scheduleTimePlaceholder: 'ระบุ Schedule Time',
    arrDep: 'ขาเข้า / ขาออก', //'ARR / DEP'
    arrDepPlaceholder: 'เลือกขาเข้า / ขาออก',
    arrival: 'ขาเข้า', //Arrival
    departure: 'ขาออก', //Departure
    traffType: 'Traff Type',
    traffTypePlaceholder: 'เลือก Traff Type',
    city: 'City (ICAO)',
    cityPlaceholder: 'ระบุ City (ICAO)',
    cityIATA: 'City (IATA)',
    cityIATAPlaceholder: 'ระบุ City (IATA)',
    cardNo: 'Card No',
    cardNoPlaceholder: 'ระบุ Card No',
    airportRemark: 'Airport Remark',
    airportRemarkPlaceholder: 'ระบุ Airport Remark',
    zoneNo: 'Zone No',
    zoneNoPlaceholder: 'ระบุ Zone No',
    regionNo: 'Region No',
    regionNoPlaceholder: '-',
    flightStatus: 'Flight status',
    flightStatusPlaceholder: 'ระบุ Flight status',
    time: 'เวลา',
    timePlaceholder: 'ระบุเวลา',
    cause: 'สาเหตุ',
    causePlaceholder: 'ระบุ สาเหตุ',
    note: 'หมายเหตุ',
    notePlaceHolder: 'ระบุ หมายเหตุ',
    pitParking: 'หลุมจอด',
    useRamp: 'ใช้ Ramp',
    unknown: 'ไม่ระบุ',
    pit: 'หลุมจอด',
    parking: 'ลานจอด',
    passengerTitle: 'ข้อมูลผู้โดยสาร',
    passenger: 'ผู้โดยสาร (คน)',
    passengerPlaceholder: 'ระบุ ผู้โดยสาร',
    domestic: 'ผู้โดยสารในประเทศ (คน)', //Passenger Domestic
    domesticPlaceholder: 'ระบุ ผู้โดยสารในประเทศ',
    internation: 'ผู้โดยสารนอกประเทศ (คน)', //Passenger Internation
    internationPlaceholder: 'ระบุ ผู้โดยสารนอกประเทศ',
    infants: 'ผู้โดยสารทารก (คน)', //Infants
    infantsPlaceholder: 'ระบุ ผู้โดยสารทารก',
    transfer: 'Passenger Transfer',
    transferPlaceholder: 'ระบุ Passenger Transfer',
    transit: 'Passenger Transit',
    transitPlaceholder: 'ระบุ Passenger Transit',
    weightTitle: 'ข้อมูลน้ำหนัก / สินค้า',
    freight: 'น้ำหนัก (กก.)', //Freight (kg.)
    freightPlaceholder: 'ระบุ น้ำหนัก (กก.)',
    freightDomestic: 'Freight Domestic (กก.)', //
    freightDomesticPlaceholder: 'ระบุ Freight Domestic (กก.)',
    freightInternation: 'Freight Internation (กก.)',
    freightInternationPlaceholder: 'ระบุ Freight Internation (กก.)',
    freightTransfer: 'Freight Transfer (กก.)',
    freightTransferPlaceholder: 'ระบุ Freight Transfer (กก.)',
    freightTransit: 'Freight Transit (กก.)',
    freightTransitPlaceholder: 'ระบุ Freight Transit (กก.)',
    postalTitle: 'ข้อมูลไปรษณีย์',
    mail: 'Mail (กก.)',
    mailPlaceholder: 'ระบุ Mail (กก.)',
    remark: 'Remark',
    remarkPlaceholder: 'ระบุ Remark',
    cancel: 'ยกเลิก',
    reject: 'ส่งกลับฯ',
    save: 'บันทึก',
    approve: 'อนุมัติ',
    grossWeight: 'Gross Weight (kg.)',
    grossWeightPlaceholder: 'Fill Gross Weight (kg.)',
    grossWeightDesc: 'ค่าไม่ตรง',
    owner: 'Owner',
    ownerPlaceholder: 'Select Owner',
    agent: 'Agent',
    agentPlaceholder: 'Fill Agent',
    agentDesc: 'Require',
    portNameIATA: 'Port Name (IATA)',
    portNameIATAPlaceholder: 'Select Port Name (IATA)',
    flightStatusRemark: 'Flight Remark',
    flightStatusRemarkPlaceholder: 'Fill Flight Remark',
    updateFlightStatus: 'Update Flight Status',
    captain: 'Captain of Aircraft',
    captainPlaceholder: 'Fill Captain of Aircraft',
    crew: 'Number of Crew',
    crewPlaceholder: 'Fill Number of Crew',
    generalDeclaration: 'General Declaration',
    mailTitle: 'Weight of Mail',
    mailInbound: 'Mail In Bound (kg.)',
    mailInboundPlaceholder: 'Fill Mail In Bound',
    mailOutbound: 'Mail Out Bound (kg.)',
    mailOutboundPlaceholder: 'Fill Mail Out Bound',
    mailTransit: 'Mail Transit (kg.)',
    mailTransitPlaceholder: 'Fill Mail Transit',
    otherTitle: 'Other'
  },
  flightEdit: {
    departmentOfAirports: 'ท่าอากาศยานในสังกัดกรมท่า ฯ',
    fromTo: 'ท่าอากาศยาน ต้นทาง / ปลายทาง'
  }
}