import React, { Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import BypassLogin from './BypassLogin'
import Login from './Login';

const LoginRouter = ({ onLoginDone }) => (
  <Router>
    <Fragment>
        <Switch>
          <Route path="/bypass-sign-in" exact component={() => <BypassLogin onLoginDone={onLoginDone}/>} />
          <Route path="/" component={() => <Login onLoginDone={onLoginDone} />} />
        </Switch>
    </Fragment>
  </Router>
);

export default LoginRouter